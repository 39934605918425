
































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import Placeholders from "@/mixins/placeholders/placeholders";
import SummarizePostHandlerModuleBuilder from '@/includes/logic/Modules/models/modules/SummarizePostHandlerModule/SummarizePostHandlerModuleBuilder';
import {
  InvertedWeekDaysIndexMap,
  WeekDaysIndexMap
} from "@/includes/logic/Modules/models/modules/CloseChatHandlerModule/types";
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import TimePickerInput from 'piramis-base-components/src/components/Pi/fields/TimePickerInput/TimePickerInput.vue'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue';
import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/types";
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';
import WeekDayPickerInput from 'piramis-base-components/src/components/Pi/fields/WeekDayPickerInput/WeekDayPickerInput.vue';
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator'

Component.registerHooks([
  'beforeRouteEnter'
])

@Component({
  data() {
    return {
      MediaEditorMode
    }
  },
  components: {
    SwitchInput,
    TimePickerInput,
    MultiMessageEditorWithMediaInput,
    NestedContent,
    WeekDayPickerInput,
    NumberInput,
  },
})
export default class SummarizePostHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders, ModulesRoutesHelperMixin) {
  @VModel() module!: SummarizePostHandlerModuleBuilder

  get day_of_week() {
    return [ +InvertedWeekDaysIndexMap[this.module.model.params.day_of_week] ]
  }

  set day_of_week(dayIndex: Array<number>) {
    this.module.model.params.day_of_week = WeekDaysIndexMap[dayIndex.at(-1) || 1]
  }

  created() {
    if (!this.module.isVisible()) {
      this.gotoModulesCatalog()
    }
  }
}
